jQuery(function($) {
    // $('.hamburger-menu').on("click", function() {
    //     const el = $('header #mobile-menu');
    //     const is_menu_open = el.hasClass('flex');
    //     const class_to_add = is_menu_open ? 'hidden' : 'flex';
    //     const class_to_remove = is_menu_open ? 'flex' : 'hidden';
    //
    //     // emulate adding styles to the element
    //     const style = is_menu_open ? 'display: none;' : 'display: flex;';
    //
    //     // el.attr('style', style);
    //     el.removeClass(class_to_remove);
    //     el[is_menu_open ? 'slideUp' : 'slideDown'](200, function() {
    //         $(this).addClass(class_to_add);
    //
    //         // remove style
    //         $(this).removeAttr('style');
    //     });
    // });
})